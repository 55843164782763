<template>
  <main>
    <ul class="form">
      <li>
        <router-link
                :to="{
            name: 'ChangePwd',
          }"
        >
          <div class="item">
            <div class="left">Password</div>
            <div class="right">******</div>
          </div>
          <div class="item">
            <div class="left tip">Set password for login</div>
            <div class="right">
              Reset
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <button class="out-login" @click="outLogin">Logout</button>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref, inject } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import router from "@/router";
  export default defineComponent({
    name: "Account",
    setup() {
      const accountData = ref({});
      axios
        .get("/M/User/Account", { params: { validate: true } })
        .then((res) => {
          if (res.data.success) {
            accountData.value = res.data.obj;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
      const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
      function outLogin() {
        localStorage.removeItem("token");
        clearUserInfo();
        router.push({
          name: "Index",
        });
      }
      return {
        accountData,
        outLogin,
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 110px;

    .form {
      li {
        border-bottom: 6px solid #f4f4f4;
        a {
          color: #444444;
          font-size: 15px;
          display: block;
          width: 100%;
          height: 100%;
          padding: 15px;
          overflow: hidden;
          box-sizing: border-box;

          .item {
            overflow: hidden;
            margin: 15px 0;
            .left {
              float: left;
            }

            .tip {
              font-size: 13px;
              color: #cccccc;
            }
            .right {
              float: right;
            }
            img {
              width: 6px;
              height: 9.5px;
              vertical-align: middle;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }

    .out-login {
      width: 345px;
      display: block;
      margin: 30px auto;
      height: 45px;
      background-color: #d64b4b;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3.75px;
      line-height: 45px;
      text-align: center;
      color: #ffffff;
      border: none;
      outline: none;
      font-size: 15px;
    }
  }
</style>
